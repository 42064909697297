
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Prose {
  // Unset common styles left in place by WYSIWYG editor
  $prose-overrides: 'box-sizing', 'color', 'font-family', 'font-size',
    'font-style', 'font-weight', 'line-height', 'margin', 'padding',
    'text-decoration', 'white-space';

  @each $property in $prose-overrides {
    [style*='#{$property}'] {
      #{$property}: inherit !important;
    }
  }

  > * + * {
    margin-top: spacing(2);
  }

  &:not(.customColor) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $color-purple;
      @supports (--css: variables) {
        color: var(--color-section-accent, $color-purple);
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: inherit;
    line-height: 1.3;
    font-weight: 700;
  }

  > {
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: spacing(4);
    }
  }

  h1,
  h2 {
    font-size: 24px;
    @include mq($breakpoint-tablet) {
      font-size: 32px;
    }
    @include mq($breakpoint-desktop) {
      font-size: 40px;
    }
    @include mq($breakpoint-desktopMedium) {
      font-size: 48px;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    @include mq($breakpoint-tablet) {
      font-size: 24px;
    }
    @include mq($breakpoint-desktop) {
      font-size: 32px;
    }
    @include mq($breakpoint-desktopMedium) {
      font-size: 40px;
    }
  }

  h4,
  h5,
  h6 {
    font-weight: 700;
    font-size: 16px;
    @include mq($breakpoint-tablet) {
      font-size: 18px;
    }
    @include mq($breakpoint-desktop) {
      font-size: 24px;
    }
    @include mq($breakpoint-desktopMedium) {
      font-size: 28px;
    }
  }

  em {
    font-style: italic;
  }

  li {
    list-style-position: outside;
    margin-left: spacing(4);

    &::marker {
      color: $color-purple;
      @supports (--css: variables) {
        color: var(--color-section-accent, $color-purple);
      }
    }
  }

  ul {
    li {
      list-style-type: disc;

      li {
        list-style-type: circle;
      }
    }
  }

  ol {
    li {
      list-style-type: decimal;

      li {
        list-style-type: lower-roman;
      }
    }
  }

  a {
    text-decoration: none;
    text-shadow: -1px -1px 0 $color-white, 1px -1px 0 $color-white,
      -1px 1px 0 $color-white, 1px 1px 0 $color-white;
    font-weight: 700;
    color: $color-black;
    display: inline;
    transition: background-size $duration-standard $easing-standard;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    background-size: 0 4px, 100% 2px;
    background-image: linear-gradient(to right, $color-green, $color-green),
      linear-gradient(to right, $color-grey80, $color-grey80);
    @supports (--css: variables) {
      background-image: linear-gradient(
          to right,
          var(--color-section-accent, $color-green),
          var(--color-section-accent, $color-green)
        ),
        linear-gradient(to right, $color-grey80, $color-grey80);
    }

    &:hover,
    &:active,
    &:focus {
      outline: 0;
      text-decoration: none;
      background-size: 100% 4px, 100% 2px;
      background-position: 0 100%, 0 100%;
    }
  }

  hr {
    height: 0;
    opacity: 0.5;
    border: 0;
    border-bottom: 1px solid currentColor;
    margin-bottom: -1px;
  }

  // Last in the group for specificity reasons
  > *:first-child {
    margin-top: 0;
  }
}

.inverse {
  color: $color-white;
  a {
    color: $color-white;

    &:hover,
    &:focus,
    &:active {
      color: $color-white;
    }
  }
}
