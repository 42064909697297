
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
// .Type {}

// Largest purple headings, article headlines
.heading1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
  @include mq($breakpoint-tablet) {
    font-size: 40px;
  }
  @include mq($breakpoint-desktop) {
    font-size: 48px;
    line-height: 1.1;
  }
  @include mq($breakpoint-desktopMedium) {
    font-size: 64px;
  }
}

// Within articles, product title
.heading2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.3;
  @include mq($breakpoint-tablet) {
    font-size: 32px;
  }
  @include mq($breakpoint-desktop) {
    font-size: 40px;
  }
  @include mq($breakpoint-desktopMedium) {
    font-size: 48px;
  }
}

// "Purple panel" title
.heading3 {
  font-weight: 700;
  font-size: 24px;
  @include mq($breakpoint-tablet) {
    font-size: 24px;
  }
  @include mq($breakpoint-desktop) {
    font-size: 32px;
  }
  @include mq($breakpoint-desktopMedium) {
    font-size: 40px;
  }
}

// Within articles, FAQ titles
.heading4 {
  font-weight: 700;
  font-size: 16px;
  @include mq($breakpoint-tablet) {
    font-size: 18px;
  }
  @include mq($breakpoint-desktop) {
    font-size: 24px;
  }
  @include mq($breakpoint-desktopMedium) {
    font-size: 28px;
  }
}

// "Card" titles, video overlay text, footer links
.heading5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  @include mq($breakpoint-tablet) {
    font-size: 18px;
    line-height: 26px;
  }
  @include mq($breakpoint-desktop) {
    font-size: 20px;
    line-height: 26px;
  }
  @include mq($breakpoint-desktopMedium) {
    font-size: 24px;
    line-height: 32px;
  }
}

// General typography, article body
.base {
  font-size: 16px;
  line-height: 22px; // 1.375
  @include mq($breakpoint-tablet) {
    font-size: 18px;
    line-height: 26px; // 1.4444444444
  }
  @include mq($breakpoint-desktop) {
    font-size: 20px;
    line-height: 26px; // 1.3
  }
  @include mq($breakpoint-desktopMedium) {
    font-size: 24px;
    line-height: 32px; // 1.3333333333
  }
}

// "Card" descriptions
.small {
  font-size: 14px;
  line-height: 20px;

  @include mq($breakpoint-desktop) {
    font-size: 16px;
    line-height: 24px;
  }
}

// Modifiers

.tight {
  line-height: 1;
}

.color-purple {
  &,
  a {
    color: $color-purple;
    @supports (--css: variables) {
      color: var(--color-section-accent, $color-purple);
    }
  }
}

.color-white {
  &,
  a {
    color: $color-white;
  }
}

.color-grey40 {
  &,
  a {
    color: $color-grey40;
  }
}

.color-yellow {
  color: $color-yellow;
}

.weight-bold {
  font-weight: 700;
}
