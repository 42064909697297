
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Container {
  width: 100%;
  clear: both;
}

.noClearfix {
  clear: none;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.gutter {
  padding-left: spacing(2);
  padding-right: spacing(2);

  @include mq($breakpoint-desktop) {
    padding-left: spacing(4);
    padding-right: spacing(4);
  }
}

// SIZES

.wide {
  max-width: spacing(160);

  &.gutter {
    max-width: spacing(160 + 2);

    @include mq($breakpoint-desktop) {
      max-width: spacing(160 + 4);
    }
  }
}

.large {
  max-width: spacing(135);

  &.gutter {
    max-width: spacing(135 + 2);

    @include mq($breakpoint-desktop) {
      max-width: spacing(135 + 4);
    }
  }
}

.mediumLarge {
  max-width: spacing(108);

  &.gutter {
    max-width: spacing(108 + 2);

    @include mq($breakpoint-desktop) {
      max-width: spacing(108 + 4);
    }
  }
}

.medium {
  max-width: spacing(90);
}

// .small {
//   max-width: spacing(60);
// }

.auto {
  max-width: none;
  width: auto;
}
