
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Panel {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    opacity: 0.75;
    box-shadow: 0 1px 5px 0 $color-grey40, 0 2px 2px 0 $color-grey40,
      0 3px 1px -2px $color-grey40;
    @include corners($crop: false);

    @supports (--css: variables) {
      box-shadow: 0 1px 5px 0 var(--color-background-dark, $color-grey40),
        0 2px 2px 0 var(--color-background-dark, $color-grey40),
        0 3px 1px -2px var(--color-background-dark, $color-grey40);
    }
  }
}

.PanelContentWrapper {
  position: relative;
  background-color: $color-white;
  width: 100%;
  @include corners;

  @include mq($breakpoint-tablet) {
    min-height: spacing(25);
  }
  @include mq($breakpoint-desktop) {
    min-height: spacing(40);
  }

  .allowOverflow & {
    overflow: visible;
  }
}

.PanelContent {
  min-width: 0;
  @include panel-spacing;
}
